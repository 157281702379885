/*  GLOBAL RESET */

* {
  box-sizing: border-box;
}

body {
  font-family: "Ubuntu", sans-serif;
  overflow-x: hidden;
  width: 100%;
}

/* ------------------------------------------------------------------------------------------------ */

/* CONTAINER CSS START */

.container-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  padding: 0.65rem !important;
}

.cust-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/* CONTAINER CSS END */

/* ---------------------------------------------------------------------------------------------------- */

/* Global css */

/* css for horizantal line */
.horizantal-line {
  width: 100%;
  height: 2px;
  background-color: rgb(97, 97, 97);
}

/* css for the container width resize */
.container-width {
  width: 60%;
}

/* csss for the page close button */
.close-img {
  position: absolute;
  top: 0px;
  right: 0px;
}

/* css for close img container */
.close-img-container {
  padding-right: 30px !important;
}

/* css for the select search box error display */
.select-search-error {
  margin-top: 1rem;
  margin-left: 1rem;
}

/* css for the file upload button */
.file-div {
  display: flex;
  justify-content: space-between;
}

.file-display {
  flex-basis: 80%;
}

.file-browse-btn {
  background: #f29c07;
  color: #fff;
  border: #f29c07 2px solid;
  border-radius: 3px;
}

.file-browse-btn-div {
  margin-top: 22px;
}

.file-input {
  display: none;
}

/* Flex css for the details page */
.details-flex-main-div {
  display: flex;
}

.details-flex-div-one {
  flex-basis: 26%;
}

.details-flex-div-two {
  flex-basis: 5%;
}

.details-flex-div-three {
  flex-basis: 70%;
}

/* flex display for the year picker and month picker */
.payslip-date-picker {
  display: flex;
  justify-content: space-between;
}

.payslip-flex-div-one {
  flex-basis: 50%;
  margin-right: 1%;
  margin-bottom: 1rem;
}

.payslip-flex-div-one input {
  width: 100%;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.payslip-flex-div-two {
  flex-basis: 50%;
  margin-left: 1%;
  margin-bottom: 1rem;
}

.payslip-flex-div-two input {
  width: 100%;
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.p-m-reset {
  padding: 0px !important;
  margin: 0px !important;
}

.col-reset {
  padding-right: 0px !important;
}

.content-center {
  justify-content: center;
}

.details-head {
  font-size: 12px;
  font-weight: bold;
}

.details-colon {
  font-size: 12px;
}

.details-value {
  font-size: 12px;
}

/* css for the form helper text material ui  */

#component-helper-text {
  color: red;
}

/* css for the modal header close button  */

.modal-header .close {
  color: rgba(233, 10, 10, 0.918);
}

/* Icons css */
.icon-color {
  color: var(--main-color) !important;
}

.MuiAutocomplete-hasPopupIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"],
.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0 !important;
}

/* LIST PAGE CSS START */
.list-label {
  font-size: 0.875rem;
  font-weight: 500;
  padding-left: 1%;
}

.list-data {
  font-size: 0.75rem;
  font-weight: 400;
  padding-left: 1%;
}

.rs-flex-around .list-rs-link:hover {
  color: white !important;
}

/* LIST PAGE CSS END */

/* new button css */
.new-btn {
  background: #27ae60;
  display: inline-block;
  position: absolute;
  top: 0.65rem;
  left: 0.65rem;
  padding: 0.275rem 0.55rem;
}

/* multi select box with check box css */
.css-yk16xz-control {
  height: 100% !important;
}

/* material ui icon size */
.mui-icon-size {
  font-size: 1.25rem !important;
}

/* GLOBAL CSS END */

/* ---------------------------------------------------------------------------------- */

/* COMPONENTS CSS START */

/* REACT SELECT BOX WITH SEARCH COMPONENT */
.select-form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  font-size: 0.875rem;
  line-height: 1;
  border-radius: 0.2rem;
}

.css-yk16xz-control {
  width: 100% !important;
}

/* ---------------------------------------------------------------------------------------- */

/* CANCEL BUTTON */

.table-cancel-btn {
  outline: none;
  cursor: pointer;
}

/* ---------------------------------------------------------------------------------------------- */

/* SELECT BOX WITH CHECK BOX */

.css-yk16xz-control {
  height: calc(1.5em + 0.5rem + 1px);
  width: 300px;
}

.css-1wa3eu0-placeholder {
  top: 40%;
  font-weight: 400;
  font-size: 0.75rem;
}

/* ICON BUTTON CSS START */

.user-onboarding-icon-btn {
  position: absolute !important;
  right: 5%;
  top: 0.65rem;
  padding: 0.125rem !important;
  color: var(--main-color) !important;
}

/* css for the employee resend mail badge */
.resend-badge {
  width: 25%;
  position: absolute;
  right: 1%;
}

.avoid-clicks {
  pointer-events: none;
}

/* ICON BUTTON CSS END */
/* ------------------------------------------------------------------------------------------------- */

/* COMPONENTS CSS END */

/* ----------------------------------------------------------------------------------- */

/* ReChart css */
.reCharts-title {
  color: rgba(0, 0, 255, 0.753);
  margin-bottom: 5px;
  font-size: 13px;
  font-weight: 600;
}

.reCharts-col {
  padding: 0px;
  max-width: 25% !important;
  margin-right: 40px;
  margin-bottom: 40px;
  height: 165px !important;
}

.reCharts-last-row-col {
  padding: 0px;
  max-width: 25% !important;
  margin-right: 40px;
  /* margin-bottom: 40px; */
  height: 165px !important;
}
.recharts-card {
  width: 100%;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.39);
  height: 140px !important;
}

.reCharts-labels-div {
  position: absolute;
  right: 5px;
  width: 150px;
  left: 150px;
  top: 10px;
}

.reCharts-labels-div ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.recharts-last-card {
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.39);
  height: 140px;
  width: 140px;
  /* margin-top: 15px; */
}

.reCharts-list {
  height: 15px;
}

.recharts-label-sm {
  font-size: 9px;
  font-weight: 400px;
  margin: 0px;
}

/* End of ReChart css */

/*  NAVBAR STYLES */

#navigation {
  padding: 0;
  z-index: 10;
  background: #fff !important;
  font-family: "Ubuntu", sans-serif;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

#navigation .navbar-nav .navs > a {
  color: #04044c;
}
#navigation .navbar-nav .nav-item > a {
  color: #0756ac;
  cursor: pointer;
  font-size: 14px;
}
#navigation .navbar-nav > li > a:hover,
#navigation .navbar-nav > li > a:focus {
  color: #04044c;
}
.navs .nav-link {
  color: #04044c;
}
.nav-icon {
  padding: 15%;
}

/*  FOOTER SECTION */

#footer {
  background: #011631;
  text-align: center;
  color: #f0f0f0;
  padding: 1rem;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
}

/*  EMPLOYEE LOGIN SECTION  */
.login-flex-div {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  height: 100vh;
  background-image: linear-gradient(to top, #ace0f9 0%, #fff1eb 100%);
  flex-wrap: wrap;
}

.login-flex-div-one {
  flex-basis: 70%;
  order: 1;
}

.login-flex-div-two {
  flex-basis: 30%;
  order: 2;
}

#employee-login {
  position: absolute;
  width: 100% !important;
  height: 100%;
  background-image: linear-gradient(to top, #ace0f9 0%, #fff1eb 100%);
}

.login-card {
  /* width: 30%;
  position: relative;
  left: 70%; */
  padding: 0 0 3% 0;
  background-image: linear-gradient(to top, #ace0f9 0%, #fff1eb 100%);
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.39); */
}

.forgot-password-card {
  width: 40%;
  margin: 10% auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.39);
}

.forgot-password-header {
  padding: 1rem;
  background-color: #000080;
  color: white;
  margin-bottom: 2rem;
}

.login-icon {
  font-size: 6rem;
  color: #0984e3;
  z-index: 1;
  background: #fff;
}

.login-form {
  padding: 5% 0;
}

.login-button {
  background: #0984e3;
  border-radius: 3px;
}

#employee-login-logo {
  position: absolute;
  top: 5%;
  left: 10%;
}

.login-heading {
  font-weight: 600;
}

#employee-login-h1 {
  position: absolute;
  top: 25%;
  left: 38%;
  font-family: "Alex Brush", cursive;
  font-size: 70px;
}

.p-logo {
  font-family: "Alex Brush", cursive;
  font-weight: bold;
}

#login-paragraph {
  margin: 3% 8% 3% 10%;
}

.login-footer {
  text-align: center;
  font-weight: bold;
  color: blue;
}

.login-heading-div-1 {
  position: absolute;
  top: 50%;
  left: 18%;
}

/*  DASHBOARD NAVIGATION  */

.sidebar-toggle-container {
  padding-left: 2%;
}
.sidebar-icon {
  color: #04044c !important;
}

.profile-edit-card {
  margin: 5% 0;
  padding: 5% 0;
  background: #fff;
  border: #ddd 2px solid;
  transition: all 0.8s;
  cursor: pointer;
}
.profile-edit-card i {
  font-size: 2rem;
}
.profile-edit-card:hover {
  background: rgba(9, 132, 227, 0.9);
  color: #fff;
}

textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="email"]:focus,
input[type="number"]:focus,
[type="text"].form-control:focus,
[type="password"].form-control:focus,
[type="email"].form-control:focus,
[type="tel"].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: inset 0 0 0 #fff;
  outline: 0 !important;
}
.add-button {
  margin: 0;
  background: #27ae60;
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
}

/*
css for the project update button in project modal
*/
.update-button {
  margin: 0;
  background: #4e7ee6;
  position: absolute;
  display: inline-block;
  top: 0;
  left: 0;
}

/*
css for the project column
*/
.projectCol {
  padding-left: 1%;
}

/*
css for the projectAssign table details
*/
.projectAssign {
  margin-top: 3%;
}

/*
project Details column css
*/
.project-details-col {
  left: -5%;
}

/*  DASHBOARD SIDEBAR  */

.scale {
  margin-left: 14%;
  padding: 0 1%;
  transition: margin-left 0.4s ease-out;
  /* margin-right: 2%; */
}
.no-scale {
  margin-left: 0;
  transition: all 0.4s ease-out;
}

.scaleGraph {
  margin-left: 14%;
  padding-left: 1%;
  /* padding: 0 1%; */
  transition: margin-left 0.4s ease-out;
  /* margin-right: 4%; */
}
.no-scaleGraph {
  margin-left: 0;
  transition: all 0.4s ease-out;
}

#no-sidebar {
  min-height: 100%;
  z-index: 8;
  width: 14%;
  margin: 0;
  background: #063594;
  backface-visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  transition: transform 0.4s ease-out;
}

#sidebar {
  padding-top: 6%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 8;
  min-height: 100%;
  backface-visibility: hidden;
  width: 14%;
  margin: 0;
  background: #063594;
  box-shadow: 3px 0 5px rgba(0, 0, 0, 0.5);
  transform: translateX(0);
  transition: transform 0.4s ease-out;
}

.sidebar-block {
  display: flex;
  flex-direction: column;
  padding: 5% 0;
}

.sidebar-main-item {
  padding: 5% 6%;
  border-bottom: 1px solid #6c7e9f;
  margin: 0;
  transition-property: transform, color;
  display: block;
  color: #333;
  transition-duration: 0.5s;
  margin-bottom: 3px;
  position: relative;
}
.sidebar-main-item::before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 2px;
  background: #f7f0f0;
  transform: scaleY(0);
  transition: transform 0.1s, width 0.1s cubic-bezier(1, 0, 0, 1);
}
.selected {
  background: #fff !important;
  color: #4b4f7e !important;
}
.sidebar-main-item:hover::before {
  transform: scaleY(1);
  width: 99%;
}
.sidebar-app-icon {
  filter: invert(100);
}
.sidebar-main-item:hover {
  cursor: pointer;
  color: #636e72;
  text-decoration: none;
}

.sidebar-main-item:hover .sidebar-app-icon {
  filter: none;
}

.sidebar-side-items {
  background: #063594;
  padding-top: 2%;
  border-top: 5px solid #063594;
}
.sidebar-side-item {
  font-size: 0.875rem;
  font-weight: 400;
  padding-left: 35%;
  margin: -1% 1% 5% 1%;
  border-radius: 100px;
  color: #333;
  position: relative;
}

#sidebar-dashboard-icon {
  font-size: 3rem !important;
}

.sidebar-mainmenu-header {
  margin-left: 1rem;
  margin-bottom: 0px;
  padding-top: 5px;
  font-weight: 400;
  font-size: 1rem;
}

.sidebar-mainmenu-img {
  background-color: #fff;
}

/*  CALENDAR   */

.icon {
  font-family: "Material Icons", serif;
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga";
}

/* CALENDAR VARIABLES */

:root {
  --main-color: #0056b3;
  --text-color: #777;
  --text-color-light: #ccc;
  --border-color: #eee;
  --bg-color: #f9f9f9;
  --neutral-color: #fff;
}

/* CALENDAR GENERAL */

.leave-icon {
  padding: 0.6rem;
  color: #eee;
  font-weight: 900;
  border-radius: 50%;
  background-image: linear-gradient(orangered, #e74c3c);
  opacity: 1;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 2px 5px #444;
}
.leave-icon:hover {
  opacity: 0.7;
}
.leave-icon:focus {
  transform: translateY(2px);
}
.details-card {
  display: block;
  padding: 0;
}
.details-card .pointer {
  margin-top: -2rem;
  margin-bottom: 1rem;
}
header #logo .icon {
  padding-right: 0.25em;
}

/* CALENDER GRID */

.row {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.row-middle {
  align-items: center;
}
.col {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
}
.col-start {
  justify-content: flex-start;
  text-align: left;
}
.col-center {
  justify-content: center;
  text-align: center;
}
.col-end {
  justify-content: flex-end;
  text-align: right;
}

/* CALENDER */

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: var(--neutral-color);
  border: 1px solid var(--border-color);
}
.calendar .header {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 115%;
  padding: 0;
  border-bottom: 1px solid var(--border-color);
}
.calendar .header .icon {
  cursor: pointer;
  transition: 0.15s ease-out;
}
.calendar .header .icon:hover {
  transform: scale(1.75);
  transition: 0.25s ease-out;
  color: var(--main-color);
}
.calendar .header .icon:first-of-type {
  margin-left: 1em;
}
.calendar .header .icon:last-of-type {
  margin-right: 1em;
}
.calendar .days {
  text-transform: uppercase;
  font-weight: 400;
  color: #777;
  font-size: 70%;
  padding: 0.75em 0;
  border-bottom: 1px solid var(--border-color);
}
.calendar .body .cell {
  position: relative;
  height: 4.5em;
  border-right: 1px solid var(--border-color);
  overflow: hidden;
  cursor: pointer;
  background: var(--neutral-color);
  transition: 0.25s ease-out;
}
.calendar .body .cell:hover {
  background: var(--bg-color);
  transition: 0.5s ease-out;
}
.calendar .body .selected,
.calendar .body .selected:hover {
  background: solid transparent;
  background-image: linear-gradient(45deg, #1a8fff 0%, #53cbf1 40%) !important;
  border-image-slice: 1;
  color: var(--neutral-color);
}
.calendar .body .row {
  border-bottom: 1px solid var(--border-color);
}
.calendar .body .row:last-child {
  border-bottom: none;
}
.calendar .body .cell:last-child {
  border-right: none;
}
.calendar .body .cell .number {
  position: absolute;
  font-size: 82.5%;
  line-height: 1;
  top: 0.75em;
  right: 0.75em;
  font-weight: 700;
}

.color .number {
  color: #fff;
}

.calendar .body .disabled {
  color: var(--text-color-light);
  pointer-events: none;
}
.calendar .body .cell .bg {
  font-weight: 700;
  line-height: 1;
  color: #0000ff;
  opacity: 0;
  font-size: 6.5em;
  position: absolute;
  top: -0.25em;
  right: -0.04em;
  transition: 0.25s ease-out;
  letter-spacing: -0.07em;
}
.calendar .body .cell:hover .bg,
.calendar .body .selected .bg {
  opacity: 0.05;
  transition: 0.5s ease-in;
}
.calendar .body .col {
  flex-grow: 0;
  flex-basis: calc(100% / 7);
  width: calc(100% / 7);
}

/*  TIMESHEET */

.time-flex {
  display: flex;
  justify-content: space-between;
}
.timesheet-toggler {
  display: inline-block;
  color: #888;
  font-size: 13px;
  font-weight: 100;
  padding: 1%;
  cursor: pointer;
  border: 1px solid #999;
  border-radius: 3px;
}
.timesheet-table {
  border: 1px solid #ccc;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.8);
}
.timesheet-input-search {
  width: 4rem;
  text-align: center;
  border: 1px solid #04044c;
  border-radius: 3px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}
.timesheet-input-day {
  width: 2.5rem;
  height: 1.2rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  border: 0;
  text-align: center;
  margin-top: 8px;
}
.timesheet-input {
  width: 3.25rem;
  height: 1.2 rem;
  border-radius: 2px;
  border: 0;
  padding: 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.8);
  margin-top: 10px;
}
.timesheet-input:focus {
  outline: none;
  border: 1px solid #27ae60;
}
.search-fail:focus {
  outline: none;
  border: solid 2px #a00;
}
.search-success:focus {
  outline: none;
  border: solid 2px #27ae60;
}
.timesheet-button {
  border-radius: 2px;
  cursor: pointer;
  text-align: center;
  color: #fff;
  display: inline-block;
  width: 3rem;
  padding: 0.25rem 0;
  font-size: 12px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.8);
}
.submit-button {
  background: darkgreen;
  margin-left: 400px;
}
.save-button {
  background: #fa8231;
}
.add-new {
  background: #2c3e50;
  padding: 2%;
  border-radius: 4px;
  color: #fff;
}
.timesheet-table tbody input {
  border: 1px solid #ccc;
  outline: none;
  padding: 5px 0;
  border-radius: 4px;
  text-align: center;
}
.timesheet-table tbody input:focus {
  border: 1px solid #1b2dcf80;
}
.timesheet-table tbody button {
  background: #2ecc71;
  border: none;
  color: #fff;
  font-size: 14px;
  padding: 0.25rem 1rem;
  border-radius: 5px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
}
/*
css for the add icon in timesheet
*/
.material-icons {
  display: inline !important;
}
/*  FUNCTIONALITY STYLES  */

.center {
  text-align: center !important;
}

.align-center {
  align-items: center !important;
}

.right {
  text-align: right;
}
.left {
  text-align: left;
}
.justify {
  text-align: justify;
}
.nav-pad {
  margin-top: 4.5%;
}
.shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.39);
}
.overlay {
  position: relative;
  transition: padding;
  transition-duration: 1s;
  z-index: 3;
  padding: 5%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.overlay:hover {
  padding: 9%;
}
.bold {
  font-weight: 400;
}
.pointer {
  cursor: pointer;
}
.heading {
  font-family: "Open Sans Condensed", sans-serif;
  font-weight: bold;
}
.no-mar {
  margin: 0 !important;
}
.m--5 {
  margin: -5px;
}
.mt--5 {
  margin-top: -15px;
}
.mr--5 {
  margin-right: -5px;
}
.ml--5 {
  margin-left: -5px;
}
.mb--5 {
  margin-bottom: -5px;
}
.mb-0-5 {
  margin-bottom: 0.5rem !important;
}
.m-auto {
  margin: "auto";
}
.pl--5 {
  padding-left: 5rem !important;
}
.pr--5 {
  padding-right: 5rem !important;
}
.allSides {
  width: 350px;
  height: 200px;
  border: solid 3px #ccc;
  background-color: #ccc;
  box-shadow: 0 0px 50px rgba(0, 0, 0, 0.6);
}

.career-row {
  background: #ddd;
  color: #444;
}
.table-row {
  background: #fff;
  color: #333;
}
.table-row:hover {
  background: #ddd;
}
.file-button {
  padding: 3px 10px;
  margin-left: 2%;
  background: #e605de;
  color: #fff;
  border: #e605de 2px solid;
  border-radius: 3px;
}
.flex-between {
  display: flex;
  justify-content: space-around;
}
.flex-left {
  display: flex;
  align-items: center;
}
.flex {
  display: flex;
  justify-content: center;
}
.flex-around {
  display: flex;
  justify-content: left;
  margin: 0;
  padding: 0;
  justify-content: space-around;
}
.flex-1 {
  flex: 1;
}

/*  COLOR CLASSES  */

.teal {
  background-color: #1abc9c;
  border: 0;
}
.purple {
  background-color: #273c75;
}
.white-text {
  color: #fff;
}
.black-text {
  color: #333;
}
.purple-text {
  color: #273c75;
}
.blue {
  background: #0984e3;
}
.blue-text {
  color: #0984e3;
}
.dark-blue-text {
  color: #04044c;
}
.grey {
  background: #ddd;
}
.btn-custom {
  color: #fff;
  background: #0984e3;
  border-radius: 3px;
}
.red-text {
  color: #ca3424;
}
.list.flex-around {
  list-style: none;
  border-radius: 3px;
  border: #ccc 1px solid;
  margin: 2px;
  padding: 2% 0;
  transition: all 0.3s;
}
.list:hover {
  background: #444;
  color: #eee;
}
.list-item {
  font-size: 11px;
  font-weight: bold;
  padding: 0 3%;
}
.list-color-item {
  font-size: 11px;
  font-weight: bold;
  padding: 0 3%;
  width: 10px !important;
}
.pad-id {
  width: 20%;
}

.pad-name {
  width: 100%;
}

.pad {
  width: 25%;
}

.color-item {
  width: 10%;
  margin-right: 25%;
  border-radius: 3px;
}

.grouped-item {
  width: 50%;
  display: inline-block;
}

/* DETAILS POPUP */

.project-details {
  position: absolute;
  width: 85%;
  top: 8%;
  z-index: 12;
  left: 15%;
  background: #fff;
  border: 2px solid #333;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/*  ACCOUNT CARD POPUP  */

.account-card {
  width: 250px;
  z-index: 10;
  position: absolute;
  top: 100%;
  margin: 0;
  padding: 0;
  background: #fff;
  transition: opacity 0.5s;
  color: #333;
  border-radius: 3%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.account-name {
  display: flex;
  align-items: center;
  padding: 5%;
}
.user-circle {
  padding: 5%;
  border-radius: 50%;
  margin-right: 2%;
  color: #fff;
  font-weight: bold;
  background: #444;
}
.account-card:after,
.account-card:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.account-card:after {
  border-color: rgba(136, 183, 213, 0);
  border-bottom-color: #fff;
  border-width: 12px;
  margin-left: -12px;
}
.account-card:before {
  border-color: rgba(194, 225, 245, 0);
  border-bottom-color: #333;
  border-width: 12px;
  margin-left: -12px;
}

/*  Hover box */

.hover-box {
  width: 15%;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 8px 20px rgba(0, 0, 0, 0.2);
  padding: 1%;
  border-radius: 4%;
}
.selected-item {
  background: #ccc !important;
}
.completed-item {
  border-right: 5px solid #27ae60 !important;
}
.searchfield {
  width: 15%;
  position: absolute;
  top: 2px;
  right: 20px;
  border: 1px solid #aaa;
}

.e360-request-searchfield {
  width: 28.5%;
  position: absolute;
  top: 0.65rem;
  right: 5%;
  border: 1px solid #aaa;
}

.canKanban-searchfield {
  /* width: 28.5%; */
  position: absolute;
  top: 0.65rem;
  right: 8%;
  border: 1px solid #aaa;
}

.center-searchfield {
  width: 50%;
  border: 1px solid #aaa;
  margin: auto;
}

/*  Attendance  */
.attendance-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.5);
  z-index: 30;
}
.attendance-main-card {
  position: absolute;
  width: 50vw;
  top: 30%;
  left: 25%;
  background: #eee;
  border: 2px solid #333;
  z-index: 31;
}
.attendance-main-card > p {
  text-transform: capitalize;
  font-weight: 400;
}
.box-label,
.box-label-attendance {
  font-size: 14px;
  margin: 0;
  padding: 3% 10%;
}
.box-label-attendance {
  margin-top: -10px;
}
.box-label:hover {
  background: #ddd;
}
.box-label > .material-icons {
  color: #999;
}
.attendance-box {
  display: flex;
  justify-content: space-evenly;
}
.attendance-box > .start-shift,
.attendance-box > .end-shift {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2% 2% 4%;
  border-radius: 5px;
}
.attendance-box > * {
  color: #888;
  font-size: 14px;
}
.box-label:hover > *,
.end-shift:hover > *,
.start-shift:hover > * {
  color: #00aee4;
}
.no-dec {
  text-decoration: none;
  color: #333;
}
.no-dec:hover {
  text-decoration: none;
  color: #333;
}
.attendance-icon:hover {
  transform: scale(1.2);
}
.flex-block {
  display: flex;
}
.blog-item {
  width: 100%;
  background: #fff;
  border: 1px solid #ddd;
  height: 60vh;
  padding: 3%;
  color: #eee;
}
.blogs {
  margin: 5%;
}
.blog-text {
  background: #b8e994;
  padding: 2%;
}
.blog-text .heading {
  margin: 2%;
}
.blog-content {
  color: #333;
  font-family: "Ubuntu", sans-serif;
  line-height: 28px;
}

/*  DOCUMENT  */
.document-card {
  width: 30vw;
  display: inline-block;
}
.doc-container {
  display: block;
  z-index: 50;
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  min-height: 120vh;
  background: rgba(0, 0, 0, 0.5);
}
.doc-item {
  position: relative;
  display: flex;
  justify-content: center;
  border: #333 2px solid;
  top: 2vh;
  left: 20vw;
  width: 70vw;
  height: 96vh;
  background: #fff;
  overflow-y: scroll;
}

/*  NOTIFICATION  */

.notification-box {
  position: fixed;
  z-index: 2000;
  top: 10px;
  right: 10px;
  padding: 5px 15px;
  border-radius: 5px;
  border-top: 3px solid #b8e994;
  border-bottom: 3px solid #b8e994;
  background: #27ae60;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation: fade linear 5000ms;
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.notification {
  padding: 0;
  color: #b8e994;
}
.notification .material-icons {
  color: #44bd32;
  font-size: 3rem;
  vertical-align: middle;
}
.error-notification-box {
  position: fixed;
  z-index: 2000;
  top: 10px;
  right: 10px;
  padding: 5px 15px;
  border-radius: 5px;
  border-top: 3px solid #fa8231;
  border-bottom: 3px solid #fa8231;
  background: #c0392b;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  animation: fade linear 5000ms;
}
.error-notification {
  padding: 0;
  color: #fff;
}
.error-notification .material-icons {
  color: #fa8231;
  font-size: 3rem;
  vertical-align: middle;
}
.back-button {
  position: relative;
  margin-top: -15px;
  left: 15px;
  bottom: -10px;
  cursor: pointer;
}
.edit-button {
  position: absolute !important;
  top: -0.6rem;
  right: 9rem;
}
.details-edit-button {
  position: absolute !important;
  top: -0.5rem;
  right: 2rem;
}
.tag-button {
  position: absolute !important;
  top: -0.6rem;
  right: 6rem;
}
.eye-button {
  position: absolute !important;
  top: -0.6rem;
  right: 3rem;
}

.cancel-button {
  position: absolute;
  top: 0.65rem;
  right: 0.65rem;
  color: #c00;
  z-index: 5;
  padding: 0;
}
.back-button:hover,
.edit-button:hover {
  color: #aaa;
}
.label-sm {
  font-size: 0.875rem !important;
  font-weight: 500;
  margin-bottom: 0.2rem;
}
.form-control-sm {
  font-size: 0.75rem !important;
  font-weight: 400;
}
.error {
  color: #f00 !important;
  font-size: 11px !important;
}
.input-error {
  color: #f00 !important;
}

.file-error {
  margin-top: -35px;
}

/*  Tech screening: */
.main-screening {
  display: flex;
  margin-top: -10px;
  justify-content: space-around;
  align-items: center;
}
.screening-card {
  width: 30%;
  display: flex;
  flex-direction: row;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
  position: relative;
  padding: 0;
}
.screening-card-item1 {
  width: 40%;
  text-align: center;
  display: inline-block;
  background: linear-gradient(to right bottom, #0abde3, #01a3a4);
  padding: 5% 2%;
  color: #fff;
}
.screening-card-item2 {
  width: 60%;
  text-align: center;
  padding: 5% 2%;
  background: #fff;
  display: inline-block;
}
.screening-card-button {
  font-size: 12px;
  display: inline-block;
  padding: 2%;
  width: 80%;
  margin-bottom: 2%;
  border-radius: 2px;
  cursor: pointer;
  color: #fff;
  background: linear-gradient(to right bottom, #0abde3, #01a3a4);
  border: 1px solid #01aea4;
}
.screening-card-button:hover {
  color: #0abde3;
  background: #fff;
}
.initial-screen-select {
  margin: 5px 0;
  display: flex;
  flex-direction: row;
}

.initial-screen-select .center {
  width: 50%;

  display: inline-block;
  padding: 0.3rem;
  font-size: 13px;
  border: 1px solid #ddd;
  border-radius: 2px;
}
.initial-screen-select .reject {
  background: red;
  border: 1px solid red;
  color: #fff;
}
.initial-screen-select .select {
  background: green;
  border: 1px solid green;
  color: #fff;
}

/*  Interview table  */
.interview-table-item:hover {
  cursor: pointer;
  background: #aaa;
}

.interview-table-head {
  background: #6c7ae0;
  color: #fff;
}

/*  Table  */
.table100 {
  background-color: #fff;
}

table {
  width: 100%;
}

th,
td {
  font-weight: unset;
  padding-right: 10px;
}

.column1 {
  width: 10%;
  padding-left: 40px;
}

.column2 {
  width: 20%;
}

.column3 {
  width: 40%;
}

.column4 {
  width: 20%;
}

.column5 {
  width: 10%;
}

.table100-head th {
  padding-top: 10px;
  padding-bottom: 10px;
}

.table100-body td {
  padding-top: 8px;
  padding-bottom: 8px;
}

/*==================================================================
[ Fix header ]*/
.table100 {
  position: relative;
  padding-top: 40px;
}

.table100-head {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.table100-body {
  max-height: 585px;
  overflow: auto;
}

/*==================================================================
[ Ver1 ]*/

.table100.ver1 th {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  line-height: 1.4;
  background-color: #6c7ae0;
}

.row100 .pointer {
  text-decoration: underline;
}

.table100.ver1 td {
  font-weight: bold;
  font-size: 12px;
  color: #808080;
  line-height: 1.4;
}

.table100.ver1 .table100-body tr:nth-child(even) {
  background-color: #f8f6ff;
}

.table100.ver1 {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0 0px 40px 0px rgba(0, 0, 0, 0.15);
}

.table100.ver1 .ps__rail-y {
  right: 5px;
}

.table100.ver1 .ps__rail-y::before {
  background-color: #ebebeb;
}

.table100.ver1 .ps__rail-y .ps__thumb-y::before {
  background-color: #cccccc;
}

.table .project-table-header th {
  padding-top: 2rem;
}

/*  MEDIA QUERIES  */

@media (max-width: 600px) {
  .login-card {
    position: absolute;
    width: 80%;
    left: 10%;
    top: 10%;
  }
  .scale {
    margin-left: 0;
    transition: all 0.4s ease-out;
  }
  #sidebar {
    width: 60vw;
    padding-top: 10vh;
  }
  .hidden-item {
    display: none;
  }
}

@media (max-width: 800px) {
  /* Login page css starts */
  .login-flex-div {
    height: 100vh;
    background-image: linear-gradient(to top, #ace0f9 0%, #fff1eb 100%);
  }

  .login-card {
    position: absolute;
    width: 80%;
    left: 10%;
    top: 100%;
  }

  #employee-login-logo {
    position: absolute;
    top: 2%;
    left: 30%;
  }

  #employee-login-h1 {
    position: absolute;
    top: 12%;
    left: 35%;
    font-family: "Alex Brush", cursive;
    font-size: 70px;
  }

  .login-heading-div-1 {
    position: absolute;
    top: 20%;
    left: 18%;
  }

  /* Login page css End */

  .scale {
    margin-left: 0;
    transition: all 0.4s ease-out;
  }
  #sidebar {
    width: 40vw;
    padding-top: 5vh;
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* 
candidate details in candidate management css for viewResume button
*/
#browseButton {
  background-color: #cf2346;
  margin-top: 23px;
  margin-left: 0px;
  padding: 2px 10px;
}

/*
 forgetpassValidate css for loading 
*/
#loading {
  margin: "1em";
  font-size: "24px";
}

/*
css for the LMS approve button in leave pending component to overwrite the background color of the button.
*/
#lms-approve-button {
  color: green !important;
  background: none;
  border: none;
}

/*
css for the LMS reject butoon in leave pending component to overwrite the background color of the button.
*/
#lms-reject-button {
  color: red !important;
  background: none;
  border: none;
}

/*
css for the LMS details button in leave pending component to view the details of the leave when mouse 
over the details button
*/
#lms-details-button {
  color: orange !important;
  background: none;
  border: none;
}

.holiday-table {
  width: 60%;
  margin: auto;
}

/*
css for leavemanagement search based on the empId and empName
*/
.leaveSearchfield {
  position: absolute !important;
  right: 50px !important;
  width: 15%;
  top: 10px;
  border: 1px solid #aaa;
}

/*
css for the cancel button in the pending leave requests  page
*/
#pendingLeaveCancel {
  margin: -5%;
  margin-left: 80%;
  font-family: sans-serif;
  color: red;
  font-size: small;
}

/* HR --> LMS --> My Holiday Calendar css */
.my-holiday-calendar-card {
  height: 270px;
  padding: 5px;
  margin: 0 5px 5px 0px;
  max-width: 32.8%;
}

.my-holiday-calendar-card-head {
  text-transform: uppercase;
  text-align: center;
  color: blue;
}

.my-holiday-calendar-days-div {
  padding: 0px;
  text-transform: uppercase;
  font-size: 14px;
}

.holiday-date-div {
  padding-right: 0px;
  padding-left: 10px;
}

.my-holiday-calendar-current-date {
  border: 1px solid #fff;
  border-radius: 100px;
  background-color: rgba(218, 97, 16, 0.986);
  color: #fff;
}

.numberHoliday {
  border: 1px solid blue;
  border-radius: 100px;
}

.myNumberHoliday {
  border: 1px solid red;
  border-radius: 100px;
}

.numberWorkDay {
  width: 27px;
  padding: 3px;
  text-align: center;
}

/* End of HR --> LMS --> My Holiday Calendar css */

/* End of LMS css */

/* HR ---> Resignation css */

.resignation-table {
  padding: 0.5rem 0 !important;
}

.resignation-span-col-1 {
  width: 42%;
  display: inline-block;
}

.revoke-img {
  position: absolute;
  top: 50px;
  right: 5px;
  z-index: 5;
  padding: 0;
}
/* End of HR ----> Resignation css */

/*
css for the policy update button
*/
#policyUpdate {
  margin: -5%;
  margin-left: 40%;
  font-family: sans-serif;
  color: olivedrab;
  font-size: small;
}

/* css for add button in Project Team Details

  */
#projectTeamADD {
  margin-right: 100px;
  margin-left: -10px;
}

/*
css for the terminate button in the employee details page
*/
.TerminateButton {
  color: white;
  margin: 0 0 10px 80%;
  background-color: coral;
}

#Reason,
#Reason1,
#endDate,
#empType,
#ConvertStartDate,
#ConvertDesignation,
#ConvertRemarks {
  width: 71%;
}

.productModal {
  width: 71%;
}

.ReasonError {
  color: red !important;
  margin: 1px 0px 0px 170px;
}

option:disabled {
  background-color: rgb(241, 241, 241);
}

/*
css for removing the border in inquiries list
*/
.InqList {
  border: none !important;
}

/*
css for the lead column 
*/
.LeadCol {
  padding-left: 1%;
  padding-right: 0;
}

.lead-ul {
  padding-left: 0;
}

.lead-grouped-item {
  width: 50%;
  display: inline-block;
}

.lead-ul2 {
  padding-left: -20px;
}

.leadDiv {
  border: 1px solid #aaa;
}

.act-list-item {
  font-size: 12px;
  padding: 0 0 0 12%;
}

.date-list-item {
  font-size: 12px;
  padding: 0 0 0 18%;
}

.desc-list-item {
  font-size: 12px;
  padding: 0 0 0 6%;
}

.time-list-item {
  font-size: 12px;
  padding: 0 0 0 12%;
}

.activity-add-button {
  padding: 2px 2%;
  background: #27ae60;
  position: absolute;
  display: inline-block;
  top: -5%;
  right: 1%;
}

.opportunity-activity-add-button {
  padding: 2px 2%;
  background: #27ae60;
  position: absolute;
  display: inline-block;
  top: -2%;
  right: 1%;
}

/*
css for the opportunity table heading
*/
.opp-head {
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}

.access-opp-head {
  font-size: 0.7rem;
  font-weight: bold;
  text-align: left;
  padding: 0.5rem !important;
}
/*
css for the opportunity table data
*/
.opp-tableData {
  font-size: 12px;
  text-align: left;
}

.access-opp-tableData {
  font-size: 11px;
  text-align: left;
  padding: 0.5rem !important;
}

/*
css for opportunity action div element
*/
.opp-action-div {
  height: 40%;
}

/*
css for the action pagination buttons
*/
.action-pagination {
  margin-top: -5%;
}

/*
css for the opportunity action edit button
*/
.opp-action-edit {
  font-size: 15px;
}

.acctionClass {
  height: 50%;
}

.opportunity-action-button {
  padding: 2px 2%;
  background: #27ae60;
  position: absolute;
  display: inline-block;
  right: 1%;
}

.opp-details-col {
  right: -5%;
}

.opp-account-field {
  display: flex;
  justify-content: space-between;
}

/*
pqPage css styling
*/
.pq-input {
  border: none;
  text-align: left !important;
  width: 3.25rem !important;
}

.pq-empty-input {
  border: 1px solid lightblue !important;
  border-radius: 3px;
}

input.pq-input:focus {
  border: 1px solid lightblue;
  border-radius: 3px;
}

#pq-container {
  box-shadow: none !important;
  margin-right: -15px;
}

#pq-servicebuttom {
  margin-bottom: 20px;
}

.pq-name-input {
  border: none;
  text-align: left !important;
  width: 7rem !important;
}

input.pq-name-input:focus {
  border: 1px solid lightblue;
  border-radius: 3px;
}

.pq-product-updatebutton {
  margin-left: 40%;
  margin-bottom: 6%;
  background-color: green;
}

.pq-product-deletebutton {
  margin-left: 20%;
}

.pq-table {
  border: 1px solid #ccc;
}

.pq-button {
  margin-bottom: 6%;
  background-color: green;
}

.pq-subtotal {
  margin-top: 2%;
  margin-left: 70%;
  margin-bottom: -5%;
  font-weight: bold;
  font-size: 13px;
}

.pq-total {
  margin-top: 1%;
  margin-left: 1%;
  margin-bottom: 0;
  font-weight: bold;
}

.pq-value {
  font-weight: normal;
}
.list-table-item {
  font-size: 11px;
  padding: 0 3%;
}

.list-req-item {
  font-size: 11px;
  padding-left: 2%;
}

.tcvgroup {
  margin-left: -12px;
  margin-bottom: 0px;
}

.Productcurrency {
  width: 110% !important;
}

.selectbox {
  height: 20% !important;
}

.campaigntime {
  width: 108% !important;
}

.account-button {
  margin-top: 30%;
}

.autocompleteDiv {
  width: 100%;
}

.suggestions {
  list-style: none;
  margin-top: -5%;
  max-height: 143px;
  overflow-y: auto;
  border-radius: 3px;
  padding-left: 0;
  -webkit-box-shadow: 1px 1px 1px 1px darkgray;
  -moz-box-shadow: 1px 1px 1px 1px darkgray;
  box-shadow: 1px 1px 1px 1px darkgrey;
  width: calc(300px + 1rem);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: lightgray;
  cursor: pointer;
  font-weight: 700;
}

/*
css for the timesSheet download button
*/
#timeSheetDownload {
  width: 20%;
  margin-left: 90%;
  margin-top: -1%;
}

#timeSheetDownloadIcon {
  color: blue;
}

#timeSheetDownloadText {
  color: black;
  width: 45%;
  margin-top: -14%;
  margin-left: 10%;
}

#serviceTimeSheetDownload {
  width: 20%;
  margin-left: 90%;
  margin-top: -1%;
}

#serviceTimeSheetDownloadIcon {
  color: blue;
}

#serviceTimeSheetDownloadText {
  color: black;
  width: 45%;
  margin-top: -14%;
  margin-left: 10%;
}

.CustDetails-ul {
  list-style: none;
  padding-left: 0px !important;
}

.employee-list-ul {
  margin: 0px !important;
}

.estimate-button {
  margin-top: 25px;
  color: #fff;
  text-align: center;
  background: #15a115;
  border-radius: 3px;
  display: inline-block;
  font-weight: 400;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.opp-currency-col {
  margin-left: -15px !important;
}

.cust-head {
  height: 30px;
}

.myprofile-span {
  margin-left: 10%;
}

.myprofile-span-col-1 {
  width: 44%;
  display: inline-block;
}

.myprofile-span-label-col-1 {
  width: 35%;
  display: inline-block;
}

.myprofile-span-lable-col-2 {
  width: 40%;
  display: inline-block;
}

.myprofile-span-lable-col-3 {
  width: 33%;
  display: inline-block;
}

.myprofile-span-lable-head-col-3 {
  width: 70%;
  display: inline-block;
  font-weight: 30%;
}

.myprofile-span-lable-head-col-2 {
  width: 80%;
  display: inline-block;
  font-weight: 30%;
}

.list-prof-table-item {
  font-size: 11px;
  font-weight: bold;
}

.list-prof-item {
  font-size: 11px;
}

.pad-prof-id {
  width: 4%;
}

/*
myProfile documents css
*/
.browseButtonTS {
  margin-top: -83px;
  margin-left: 430px;
}

.document-file {
  width: 83%;
}

/*
myProfile Education Certification css
*/
.my-profile-cert-id {
  padding-right: 0px !important;
}

.my-profile-cert-name {
  padding-right: 0px !important;
  width: 20% !important;
}

.my-profile-cert-version {
  padding-right: 0px !important;
  width: 10% !important;
}

.my-profile-cert-specialization {
  padding-right: 0px !important;
  width: 10% !important;
}

.my-profile-cert-InstName {
  padding-right: 0px !important;
  width: 10% !important;
}

.my-profile-cert-compDate {
  padding-right: 0px !important;
  width: 10% !important;
}

.my-profile-cert-validDate {
  padding-right: 0px !important;
  width: 7% !important;
}

.my-profile-cert-grade {
  padding-right: 0px !important;
  width: 10% !important;
}

.my-profile-cert-percentage {
  padding-right: 0px !important;
  width: 10% !important;
}

/*
css for the myProfile BankInformation
*/
.my-profile-bank-info {
  display: block;
  margin: -3.8% 0 0 24%;
}

/*
css for the myProfile Nominee
*/
.my-profile-nominee-dob {
  width: 8% !important;
}

/*
css for the myProfile honors and awards
*/
.my-profile-honors-date {
  width: 15% !important;
}

/* css for the myProfile Account details */
.bank-info-row {
  width: 50%;
  margin: auto;
}

/*
css alignment for all menus details page alignment.
*/
/*
    Innovation Details
    */
.idea-span-label-col-1 {
  width: 26%;
  display: inline-block;
}

.idea-span-label-col-2 {
  width: 28%;
  display: inline-block;
}

.idea-span-data-col-1 {
  display: block;
  margin: -4% 0 0 30%;
}

.idea-span-data-col-2 {
  display: block;
  margin: -4% 0 0 32%;
}

/*
    CRM Customers Details
    */
.customer-span-label-col-1 {
  width: 24%;
  display: inline-block;
}

.customer-span-label-col-2 {
  width: 23%;
  display: inline-block;
}

/*
    CRM Account details 
    */
.accounts-span-label-col-1 {
  width: 21%;
  display: inline-block;
}

.accounts-span-label-col-2 {
  width: 22%;
  display: inline-block;
}

/*
    CRM Contacts Details
    */
.contact-span-label-col-1 {
  width: 24%;
  display: inline-block;
}

.contact-span-label-col-2 {
  width: 29%;
  display: inline-block;
}

.tag-contact-span-label-col-2 {
  width: 30%;
  display: inline-block;
}

.tag-view-delete-btn {
  position: absolute;
  right: 2%;
  color: red;
}

/*
    CRM ProductCatalog Details
    */
.productCatalog-span-label-col-1 {
  width: 20%;
  display: inline-block;
}

.productCatalog-span-label-col-2 {
  width: 14%;
  display: inline-block;
}

/*
    CRM Marketing Campaign Details
    */

/* Marketing Insights css */

/* MarketingChart1 CSS Start */
#marketingChart1-one-list {
  content: "";
  display: inline-block;
  width: 8px;
  height: 8px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  /* background-color: #0088fe; */
  margin-right: 5px;
}

.marketingChart1-li-span {
  width: 80px;
  display: inline-block;
}

.marketingChart1-li-span-crm-customerLocation {
  width: 88px;
  display: inline-block;
}
/* MarketingChart1 CSS End */

/* MarketingChart2 CSS Start */
.marketingChart2-li-span {
  width: 40px;
  display: inline-block;
}

/* MarketingChart2 CSS End */

/* End of Marketing Insights CSS */
.campaign-span-label-col-1 {
  width: 22%;
  display: inline-block;
}

.campaign-span-label-col-2 {
  width: 16%;
  display: inline-block;
}

/*
    CRM Marketing Infquiry Details
    */
.inquiry-span-label-col-1 {
  width: 18%;
  display: inline-block;
}

.inquiry-span-label-col-2 {
  width: 18%;
  display: inline-block;
}

/*
    CRM Sales leads Details
    */
.lead-span-label-col-1 {
  width: 20%;
  display: inline-block;
}

/* .lead-span-label-col-2 {
      width: 40%;
      display: inline-block;
    } */

/*
    CRM Sales opportunity Details
    */
.opportunity-span-label-col-1 {
  width: 20%;
  display: inline-block;
}

/* .opportunity-span-label-col-2 {
      width: 40%;
      display: inline-block;
    } */

/*
    CRM Sales order Details
    */
.order-span-label-col-1 {
  width: 22%;
  display: inline-block;
}

.order-span-label-col-2 {
  width: 32%;
  display: inline-block;
}

/*
    PPS Product Details
    */
.product-span-label-col-1 {
  width: 22%;
  display: inline-block;
}

.product-span-label-col-2 {
  width: 14%;
  display: inline-block;
}

/*
    PPS Project Details
    */
.project-span-label-col-1 {
  width: 34%;
  display: inline-block;
}

.project-span-label-col-2 {
  width: 50%;
  display: inline-block;
}

/*
    PPS Service Details
    */
.service-span-label-col-1 {
  width: 46%;
  display: inline-block;
}

.service-span-label-col-2 {
  width: 46%;
  display: inline-block;
}

.pad-crm-id {
  width: 8%;
}

.pad-product-id {
  width: 8%;
}

.pad-product-name {
  width: 50%;
}

.pad-product-status {
  width: 15%;
}

/* CRM CSS START */
/* COMMON CSS START */

/* COMMON CSS END */
/* CRM -- CUSTOMER CSS START */
.custId-width {
  width: 8%;
}

.custName-width {
  width: 28%;
}

.custType-width {
  width: 15%;
}

.custDomain-width {
  width: 15%;
}

.custContact-width {
  width: 25%;
}

.custStatus-width {
  width: 9%;
}
/* CRM -- CUSTOMER CSS END */

/* CRM -- ENTITY CSS START */
.entityId-width {
  width: 10%;
}

.entityCust-width {
  width: 30%;
}

.entityName-width {
  width: 25%;
}

.entityReg-width {
  width: 17%;
}

.entityPan-width {
  width: 8%;
}

.entityStatus-width {
  width: 7%;
}
/* CRM -- ENTITY CSS END */

/* CRM -- ACCOUNT CSS START */
.accId-width {
  width: 12%;
}

.accName-width {
  width: 20%;
}

.accCust-width {
  width: 22%;
}

.accEntity-width {
  width: 20%;
}

.accContact-width {
  width: 20%;
}

.accStatus-width {
  width: 7%;
}
/* CRM -- ACCOUNT CSS END */

/* CRM -- CONTACT CSS START */
.contId-width {
  width: 6%;
}

.contName-width {
  width: 15%;
}

.contEmail-width {
  width: 15%;
}

.contMobile-width {
  width: 10%;
}

.contCust-width {
  width: 15%;
}

.contEntity-width {
  width: 15%;
}

.contAcc-width {
  width: 15%;
}

.contStatus-width {
  width: 6%;
}

/* CRM -- CONTACT CSS END */
.list-crm-item {
  font-size: 14px;
  font-weight: 600;
  padding-left: 2%;
}

.list-crm-table-item {
  font-size: 12px;
  padding-left: 2%;
}

.pad-crm-name {
  width: 32%;
}

.pad-crm-status {
  width: 22%;
}

.crm-flex-around {
  display: flex;
  justify-content: left;
  list-style: none;
  border-radius: 3px;
  margin: 2px;
  padding: 1% 0;
  transition: all 0.3s;
}

/* CRM CSS END */

/* RS CSS START */
.canKanbanId-width {
  width: 15%;
}

.canKanbanName-width {
  width: 30%;
}

.canKanbanMobile-width {
  width: 30%;
}

.canKanbanStatus-width {
  width: 25%;
}

.canKanbanStage-width {
  width: 30%;
}

/* RS MANAGER VIEW CSS START */
/* .manager-view-select-box {
  width: 95% !important;
} */

.searchWrapper {
  min-height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1;
  border-radius: 0.2rem;
}

.manager-date-input {
  font-size: 0.7rem !important;
}

#demo-simple-select-outlined-label {
  font-size: 0.75rem !important;
  font-weight: 400 !important;
}

.mv-tagged-label {
 
  margin-left: 0 !important;
  margin-bottom: 0 !important;
  font-size: 7px;
  line-height: normal;
}

.mv-updated-label {
 
  margin-bottom: 0 !important;
  font-size: 0.875rem !important;
  line-height: normal;
}
/* RS MANAGER VIEW CSS END */
/* RS CSS END */

.product-pq-button {
  cursor: pointer;
  display: inline !important;
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}

#product-pq-add-button {
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 5px;
  font-size: 20px;
}

#product-pq-add-button:hover {
  background-color: grey;
}

#product-pq-save-button {
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 5px;
  font-size: 20px;
}

#product-pq-save-button:hover {
  background-color: grey;
}

#product-pq-edit-button {
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 5px;
  font-size: 20px;
}

#product-pq-edit-button:hover {
  background-color: grey;
}

#product-pq-delete-button {
  border: 1px solid lightblue;
  border-radius: 33px;
  padding: 5px;
  font-size: 33px;
  margin-bottom: -4%;
  vertical-align: baseline !important;
}

#product-pq-delete-button:hover {
  background-color: grey;
}

.pq-product-td {
  padding: 0px !important;
  width: 10px;
  padding-right: 0.2em !important;
  padding-bottom: 0.5em !important;
}

#lead-activity-edit-button {
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 5px;
  font-size: 20px;
}

#lead-activity-edit-button:hover {
  background-color: grey;
}

#prospects-activity-edit-button {
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 5px;
  font-size: 20px;
}

#prospects-activity-edit-button:hover {
  background-color: grey;
}

#oppr-milestone-edit-button {
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 5px;
  font-size: 20px;
}

#oppr-milestone-edit-button:hover {
  background-color: grey;
}

#oppr-activity-edit-button {
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 5px;
  font-size: 20px;
}

#oppr-activity-edit-button:hover {
  background-color: grey;
}

#project-team-add-button {
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 5px;
  font-size: 20px;
}

#project-team-add-button:hover {
  background-color: grey;
}

#project-team-save-button {
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 5px;
  font-size: 20px;
}

#project-team-save-button:hover {
  background-color: grey;
}

#project-team-edit-button {
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 5px;
  font-size: 20px;
}

#project-team-edit-button:hover {
  background-color: grey;
}

#project-team-delete-button {
  border: 1px solid lightblue;
  border-radius: 33px;
  padding: 5px;
  font-size: 33px;
  margin-bottom: -4%;
  vertical-align: baseline !important;
}

#project-team-delete-button:hover {
  background-color: grey;
}

#service-team-add-button {
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 5px;
  font-size: 20px;
}

#service-team-add-button:hover {
  background-color: grey;
}

#service-team-save-button {
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 5px;
  font-size: 20px;
}

#service-team-save-button:hover {
  background-color: grey;
}

#service-team-edit-button {
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 5px;
  font-size: 20px;
}

#service-team-edit-button:hover {
  background-color: grey;
}

#service-team-delete-button {
  border: 1px solid lightblue;
  border-radius: 33px;
  padding: 5px;
  font-size: 33px;
  margin-bottom: -4%;
  vertical-align: baseline !important;
}

#service-team-delete-button:hover {
  background-color: grey;
}

/*
css for the prospect activities column
*/
.prospect-activity-col {
  padding: 0px !important;
}

/*
css for the innovation idea list heading
*/
.list-idea-item {
  font-size: 11px;
  font-weight: bold;
  padding-left: 2%;
}

.idea-flex-around {
  display: flex;
  justify-content: left;
  list-style: none;
  border-radius: 3px;
  margin: 2px;
  padding: 2% 0;
  transition: all 0.3s;
}

/*
css for the customers tree stucture dat
*/
.tree-div {
  padding: 5px;
  border-radius: 8px;
  display: inline-block;
  border: 1px solid red;
}

.tree-div:hover {
  background-color: lightblue;
}

.tree-div-level1 {
  padding: 5px;
  border-radius: 8px;
  display: inline-block;
  border: 1px solid red;
}

/*
css for the customer details button
*/
.custDetails-button {
  position: absolute;
  top: 5px;
  color: rgb(204, 0, 119);
  z-index: 5;
  padding: 0;
}

/*
css for the account details modal in customer organizational chart
*/
.account-modal-details {
  width: 20%;
  display: inline-block;
}

/*
css for the contacts details modal in customer organizational chart
*/
.contact-modal-details {
  width: 28%;
  display: inline-block;
}

/*
css for the cust-chart-button 
*/
.cust-chart-button {
  background: #24b307;
  border-radius: 3px;
  position: absolute;
  top: 0%;
}

/*
css for the task to do component
*/
.task-id {
  padding: 0px !important;
}

.task-name {
  padding: 0px !important;
}

/*
css for the team activity page
*/
.team-activity-div {
  position: absolute;
  width: 85%;
  height: 100%;
  top: 8%;
  z-index: 12;
  left: 15%;
  background: #fff;
  border: 2px solid #333;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/*
css for the project activity list display
*/
.pad-projActivity-id {
  width: 8%;
}

.pad-projActivity-name {
  width: 32%;
}

.pad-projActvity-date {
  width: 20%;
}

/* ppsInsights and crmInsights */

/*
css for the services graph represetation row
*/
.graph-row-2 {
  margin-top: 1%;
}

/*
css for the pps Insights cards
*/
.graph-card {
  width: 100%;
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.39);
}

.graph-col {
  margin: auto;
}

/*
milestone css
*/
.milestone-div {
  width: 100%;
}

.milestone-row {
  height: 50%;
}

.milestone-add-btn {
  padding: 2px 2%;
  background: #27ae60;
  position: absolute;
  display: inline-block;
  top: -2%;
  right: 1%;
}

.milestone-head {
  font-size: 12px;
  font-weight: bold;
}

/*
task css
*/
.task-div {
  width: 100%;
}

.task-row {
  height: 50%;
}

.task-add-btn {
  padding: 2px 2%;
  background: #27ae60;
  position: absolute;
  display: inline-block;
  right: 1%;
  top: 49%;
}

/*
MyProfile css
*/
#welcome-div {
  background-color: #000080;
  width: 60%;
  margin: auto;
}

#welcome-p {
  font-size: 16px;
  font-weight: bolder;
  color: #fff;
  text-align: center;
  margin: 1%;
}

#myprofile-category-div {
  background-color: rgba(201, 193, 193, 0.26);
  width: 60%;
  margin: auto;
}

.myprofile-card {
  margin: 3% 0;
  text-align: center;
  padding: 3%;
  cursor: pointer;
  width: 100%;
  background-color: #bbdefb;
  /* background-color: linear-gradient(blue, pink); */
  color: black;
}

.myprofile-card:hover {
  background-color: #1565c0;
  color: white;
  transition: ease-in-out;
}

.myprofile-card-icon {
  color: black;
}

.myprofile-card:hover .myprofile-card-icon {
  color: white !important;
  transition: ease-in-out;
}

.myprofile-icon {
  font-size: 24px;
}

/*
personal details css
*/
.personalDetails-div-1 {
  width: 60%;
}

.personal-span-col-1 {
  width: 33%;
  display: inline-block;
}

.personal-details-div {
  margin: auto;
  width: 60%;
}

.personal-details-div ul {
  list-style: none;
  width: 100%;
  margin: 16px 0px 16px 25px !important;
  padding: 0px !important;
}

.personal-details-div ul li {
  padding: 0px;
}

.personal-head {
  margin-left: 200px;
}

/* Nomination --> dependents css
*/
.dependent-edit-btn-td {
  width: 53px;
}

.dependent-delete-btn-td {
  width: 66px;
}
/*
unorder list stylings
*/
.ul-head {
  border: none !important;
  background-color: #e6f2ff;
}

/*
css for the platform business setup bussiness geographies page
*/
.pad-id {
  width: 10%;
}

.pad-countryCode {
  width: 10%;
}

.pad-countryName {
  width: 15%;
}

.pad-remarks {
  width: 15%;
}

/* e360 --> Request Kanban list */
.pad-req-id {
  width: 12%;
}

.pad-reqType {
  width: 18%;
}

.pad-title {
  width: 50%;
}

.pad-severity {
  width: 17%;
}

/*
css for platform legalEntities
*/
.platform-legalEntity-date {
  padding-right: 0px !important;
  width: 8% !important;
}

/*
platform css
*/
.platform-head {
  background-color: #000080;
  width: 60%;
  margin: auto;
}

.platform-p {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin: 2%;
}

.platform-category-div {
  /* background-color: rgba(201, 193, 193, 0.26); */
  width: 60%;
  margin: auto;
}

/*
css for the platform auth configuration ---> Access and Permission
*/
.main-menu-access {
  margin-top: 30px;
}

.access-and-permission-label {
  margin-left: 10px;
}

#main-menu-done-btn {
  font-size: 40px;
  margin-top: 25px;
  margin-left: 470px;
  color: blue;
}

.access-main-menu-done-btn {
  font-size: 25px;
  color: blue;
  margin-top: -5px;
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 5px;
  position: sticky;
  left: 1000px;
  height: 37px;
}

.access-main-menu-done-btn:hover {
  background-color: gray;
}

.access-sub-menu-done-btn {
  font-size: 25px;
  color: blue;
  margin-top: -5px;
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 5px;
  position: sticky;
  left: 10000px;
}

.access-sub-menu-done-btn:hover {
  background-color: gray;
}

.access-roleId-list:hover {
  background-color: rgba(170, 166, 166, 0.822);
}

.add-access-col {
  padding-right: 0px;
  max-width: 150px !important;
}

.sub-menu-access-update {
  padding-right: 0px;
}

.sub-menu-update-access {
  max-width: 150px;
}

.sub-menu-update-create {
  max-width: 90px;
}

.sub-menu-update-view {
  max-width: 80px;
}

.sub-menu-update-update {
  max-width: 95px;
}

.sub-menu-update-delete {
  max-width: 90px;
}

.access-edit-btn {
  border: 1px solid lightblue;
  border-radius: 20px;
  padding: 5px;
  font-size: 20px;
}

.access-edit-btn:hover {
  background-color: gray;
}

/* Platform ---> HCM Setup ---> CompanyHolidays css */

.platform-hcmsetup-companyholiday-table {
  width: 80%;
  margin: auto;
}

.hcm-onboarding-search-filter {
  position: relative;
  margin-left: 5%;
}

.hcm-onboarding-search-filter-icon {
  position: absolute;
  top: 0.01rem;
  left: 72%;
  z-index: 1;
}

.hcm-onboarding-search-filter-icon ul {
  list-style-type: none;
  background-color: white;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 8px 20px rgba(0, 0, 0, 0.2);
}

.display-filter {
  display: block;
  transition: ease-in-out 0.5ms;
}

.not-display-filter {
  display: none;
  transition: ease-in-out 0.5ms;
}

.hcm-onboarding-search-filter-icon ul li {
  padding: 0.2rem;
  cursor: pointer;
}

.hcm-onboarding-search-filter-icon ul li:hover {
  background-color: lightgray;
}
/* End of  Platform ---> HCM Setup ---> CompanyHolidays css */

/* End of Platform */

/*
css for the scrollbar
*/
.simplebar-track.simplebar-vertical {
  background-color: #fff;
}

/*
css for the employee onboarding menu
*/
.emp-p {
  margin-bottom: 1% !important;
}

.emp-span-label {
  width: 20%;
  display: inline-block;
}

.emp-span-label-col-1 {
  width: 26%;
  display: inline-block;
}

.emp-span-label-col-2 {
  width: 23%;
  display: inline-block;
}

.emp-span-label-col-3 {
  width: 26%;
  display: inline-block;
}

.emp-span-label-rs {
  width: 38%;
  display: inline-block;
}

/*
css for the feedback
*/
.feedback-span-label-col-1 {
  width: 23%;
  display: inline-block;
}

.feedback-span-label-col-2 {
  width: 25%;
  display: inline-block;
}

.feedback-span-data-col-1 {
  display: block;
  margin: -3.7% 0 0 25.6%;
}

.feedback-span-data-col-2 {
  display: block;
  margin: -3.7% 0 0 27.6%;
}

/*
css for employee onboarding
*/
.employee-list-col {
  padding: 0 0 0 0.5rem !important;
}

.employee-list-span-data {
  display: block;
  margin: -5.5% 0 0 25%;
}

/*
css for the employment details
*/
.employment-th-name {
  padding-right: 0px !important;
  width: 12% !important;
}

.employment-th-desig {
  padding-right: 0px !important;
  width: 14% !important;
}

.employment-th-fromDate {
  padding-right: 0px !important;
  width: 12% !important;
}

.employment-th-toDate {
  padding-right: 0px !important;
  width: 12% !important;
}

.employment-th-noOfYears {
  padding-right: 0px !important;
  width: 15% !important;
}

.employment-th-address {
  padding-right: 0px !important;
  width: 20% !important;
}

.table-edit-btn {
  width: 1%;
}

.table-delete-btn {
  width: 1%;
}

.span-edit-btn {
  font-size: 1.5rem !important;
  /* border: 1px solid lightblue;
    border-radius: 20px;
    padding: 5px; */
}

.span-delete-btn {
  font-size: 1.5rem !important;
  /* border: 1px solid lightblue;
    border-radius: 20px;
    padding: 5px; */
}

/*
css for the contact details
*/
.contact-details-col {
  padding: 0 !important;
  min-height: 278px;
  background-color: rgba(243, 241, 241, 0.863);
  margin: 0.25em !important;
}

.contact-details-col h5 {
  margin-top: 0.5rem;
}

.contact-details-col ul {
  list-style: none;
  margin-top: 8%;
}

.contact-details-col li {
  margin-bottom: 5%;
}

.contact-address-li {
  margin-bottom: 0 !important;
  min-height: 100px;
}

.contact-cancel-button {
  position: absolute;
  top: -10px;
  right: -10px;
  color: #c00;
  z-index: 5;
  padding: 0;
}

.contact-add-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(54, 0, 204);
  z-index: 5;
  padding: 0;
}

.contact-edit-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(54, 0, 204);
  z-index: 5;
  padding: 0;
}

/*
css for the contact details - contact detail
*/
.contact-details-span {
  width: 21%;
  display: inline-block;
}

/*
css for the contact details - address
*/
.contact-address-span {
  width: 28%;
  display: inline-block;
}

.contact-address-data-span {
  width: 50%;
  display: inline-block;
}

.contact-address-data {
  display: block;
  margin: -3.8% 0 0 31%;
}

/*
css for the emergencycontactdetails 
*/
.emergency-contact-name-span {
  display: block;
  margin: -3.8% 0 0 20%;
}

/*
css for the familycontactdetails
*/
.family-contact-span {
  display: block;
  margin: -3.8% 0 0 27%;
}

/*
css for the contact details - emergency-details
*/
.contact-emergency-details-span {
  width: 17%;
  display: inline-block;
}

/*
css for the contact details - family-details
*/
.contact-family-details-span {
  width: 24%;
  display: inline-block;
}

/*
css for Identification Info 
*/
.other-identifiers-span {
  width: 26%;
  display: inline-block;
}

.my-profile-identification-info-span-data {
  display: block;
  margin: -3.8% 0 0 24%;
}

/*
css for the if user disconnected from the internet alert message will be display
*/
.internet-info-alert {
  margin: -30px 0 0 0;
  padding: 0px;
}

.internet-info-alert p {
  text-align: center;
  margin: 10px;
}

/*
css for the employee info details page
*/

.employee-info-row {
  margin-top: 10px;
}

.employee-info-ul {
  list-style: none;
  padding: 0;
}

.employee-info-col-one {
  max-width: 20.7%;
  padding-right: 0;
}

#employee-info-alternative-no {
  display: inline-block;
  width: 47.5%;
}

.employee-info-col-two {
  padding-right: 0;
}

.employee-info-address-data {
  display: block;
  margin: -5% 0 0 42%;
}

#employee-info-address-two-span {
  display: inline-block;
  width: 30.7%;
}

.employee-info-col-three {
  padding-right: 0;
}

.employee-info-col-three ul li span.bold {
  display: inline-block;
  width: 23%;
}

.employee-info-col-four {
  padding-right: 0;
}

.employee-info-col-four ul li span.bold {
  display: inline-block;
  width: 33%;
}

.employee-info-row-two-col-one {
  max-width: 20.7%;
  padding-right: 0;
}

#employee-info-aadhar-span {
  display: inline-block;
  width: 39%;
}

.employee-info-row-two-col-two {
  padding-right: 0;
}

.employee-info-inside-row-col {
  padding: 0;
}

.employee-info-data-one {
  display: inline-block;
  width: 37%;
}

.employee-info-data-two {
  display: inline-block;
  width: 30%;
}

.employee-info-row-three-col-one {
  padding-right: 0;
  max-width: 20.7%;
}

.employee-info-row-three-col-one-span {
  display: inline-block;
  width: 40%;
}

.employee-info-row-three-col-two {
  padding-right: 0;
}

.employee-info-row-three-col-two ul li span.bold {
  display: inline-block;
  width: 20%;
}

.employee-info-head {
  font-size: 16px;
}

.employee-info-row-three-col-three {
  padding-right: 0;
}

.employee-info-row-three-col-three ul li span.bold {
  display: inline-block;
  width: 25%;
}

.employee-info-certification-col {
  padding-right: 0;
}

.employee-info-certification-col ul li span.bold {
  display: inline-block;
  width: 32%;
}

/* Operations ----> Partners CSS Start */

/* Partners ---> Partner Details CSS Start */

.partnerDetails-span-label-col-3 {
  width: 44%;
  display: inline-block;
}

/* Partners ----> Partner Details ----> Document Upload Start */

.operations-documents-details-span {
  display: inline-block;
  width: 31%;
}

.partner-li {
  position: relative;
}

.partner-fileView-img {
  position: absolute;
  right: 10%;
}

.partner-fileDownload-img {
  position: absolute;
  right: 1%;
}

/* Partners ----> Partner Details ----> Document Upload End */

/* Partners ---> Partner Details CSS End */

/* Operations ----> Partners CSS End */

/* Finance -- Payslip  */
.payslip-download-img {
  cursor: pointer;
}

.mypayslips-year-th {
  width: 10rem;
}

.mypayslip-datepicker-year {
  width: 8rem;
}

.mypayslip-datepicker-year input {
  border-radius: 3px;
  width: 4rem;
  margin-left: 10%;
}

/* css for finance --> accounts payable ---> Enter Invoice */
.enter-invoice-span-label-col-2 {
  display: inline-block;
  width: 22%;
}

.purchaseInvoice-details-flex-div-one {
  flex-basis: 34%;
}

/* css for finance --> accounts payable ---> Issue PO */
.poDetails-span-label-col-1 {
  width: 27%;
  display: inline-block;
}

.addIssuePo-purchaseItems-div {
  height: 60%;
}

.addIssuePo-pymtSchedule-div {
  height: 40%;
}

.addIssuePo-paymentSchedule-add-btn {
  padding: 2px 2%;
  background: #27ae60;
  position: absolute;
  display: inline-block;
  right: 1%;
  top: 59%;
}

.updateIssuePo-purchaseItems-div {
  height: 50%;
}

.addIssuePo-paymentTerms-div {
  margin-bottom: 100px;
}

/* css for the accounts receivable */
/* accounts receivable --> po entry css */
.poEntry-purchaseItem-row {
  height: 70%;
}

.UpdatepoEntry-purchaseItem-row {
  height: 50%;
}

.poEntry-pymtSchedule-add-btn {
  padding: 2px 2%;
  background: #27ae60;
  position: absolute;
  display: inline-block;
  right: 1%;
  top: 69%;
}

.input-candisearch {
  border: 1px solid #ccc;
  background-color: rgb(243, 255, 255);
}

/* ...search candi .. */

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}
.opp-tableData .tooltiptext {
  display: flex;
  flex-direction: column;
  visibility: hidden;
  width: 700px;
  background-color: #fff;
  color: black;

  border-width: 1rem;
  /* text-align: center; */
  border-radius: 6px;
  padding: 1rem 1rem;
  position: absolute;
  z-index: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 8px 20px rgba(0, 0, 0, 0.2);
  /* left: 50%; */
  /* margin-left: -60px; */
  opacity: 0;
  transition: opacity 0.3s;
  overflow-y: auto;
  max-height: 400px;
  height: auto;
}

.opp-tableData .tooltiptext::after {
  content: "";
  position: absolute;

  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}
/* .opp-tableData:hover{
  color:red;
  background: yellow;
} */
.tablecandidaterow:hover {
  background: #404040;
  color: white;
}
.tablecandidaterow:hover .tooltiptext {
  visibility: visible;

  opacity: 1;
}
.searchplatform-category-div {
  display: flex;
  /* background-color: rgba(201, 193, 193, 0.26); */
  width: 100%;
  margin-top: 1rem;
}

.pad-rs-name {
  width: 18%;
  font-size: 0.75rem;
  font-weight: 400;
}

.cansearch-rs-name {
  width: 20%;
  font-size: 12px;
  font-weight: 400;
}

.rs-flex-around {
  display: flex;
  justify-content: left;
  list-style: none;
  border-radius: 3px;
  width: 100%;
  margin: 2px;
  padding: 1% 0;
  transition: all 0.3s;
}

.rs-flex-around:hover .tooltiptext {
  visibility: visible;

  opacity: 1;
}
.rs-flex-around:hover {
  background: #404040;
  color: white;
}
.id-cursor{
  width: 8%;
  padding-left: 2% ;
  color: blue;
  cursor: pointer;
}

.rs-flex-around:hover .id-cursor {
 color: white;
}

.cancount-cursor{
  overflow: hidden;
  width: 10%;
 color: blue;
  cursor: pointer;
}

.rs-flex-around:hover .cancount-cursor {
  color: white;
 }



.hoverrowrs:hover {
  background: #404040;
}

.pad-can-name {
  width: 25%;
}

.pad-cansearch-name {
  width: 25%;
}

.details-flex-main-div {
  display: flex;
  margin-bottom: 2%;
}

.contact-details-flex-div-one {
  flex-basis: 35%;
  font-size: 1rem;
  font-weight: bold;
  list-style: none;
  list-style-type: none;

}

.details-flex-div-two {
  flex-basis: 7%;
}

.details-flex-div-three {
  flex-basis: 70%;
  font-size: 1rem;
  font-weight: normal;
  overflow: hidden;
}

.rs-req-span-label {
  width: 35%;
  display: inline-block;
  /* margin: -5.5% 0 0 40%; */
}

.rs-req-list-span-data {
  display: block;
  margin: -5.5% 0 0 40%;
}

.rs-req-span-label-col-1 {
  width: 44%;
  display: inline-block;
}

/* E360 CSS START */
.e360-request-eye-button {
  width: 3%;
  position: absolute !important;
  top: 0.65rem;
  right: 1%;
  height: 1.9rem;
  color: var(--main-color) !important;
  padding: 0.1rem;
}
/* Add a black background color to the top navigation */
.topnav {
  color:grey;
  
}

.dark-theme-manview{
  border-bottom:  3px solid #00f;
  color: black;
}

.light-theme-manview{
  color: grey;
}

/* Style the links inside the navigation bar */
.topnav a {
  /* float: left;
  display: block;
  color: #f2f2f2;
  text-align: center; */
  cursor: pointer;
  font-weight: 500;
  /* padding: 14px 16px; */
  text-decoration: none;
  font-size: 1rem;
  /* border-bottom: 3px solid transparent; */
}


.grid-graph-one{
    display: grid;
    grid-template-columns: 0.5fr 0.5fr 0.5fr 0.5fr  0.5fr  0.5fr ;
    grid-template-rows: 1fr 100px;
    grid-column-gap: 20px;
    grid-row-gap: 20px;
  
    /* justify-items: stretch;
    align-items: stretch; */
    background-color: red;
}

.flex-Graphcontainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;

  /* background-color: DodgerBlue; */
}

.flex-Graphcontainer > div {
  /* background-color: #f1f1f1; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 6.6rem;
  margin: 1px;
  text-align: center;
  line-height: 40px;
  font-size: 0.80rem;
  display:flex;
  flex-direction:column;
  cursor: pointer;

}

.flex-GraphcontainerTwo{
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}


.graphChild-col-1{
  /* background-color: #f1f1f1; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 30%;
  height: 260px;
   margin: 5px;
   font-size: 0.75rem;
  flex-wrap: wrap;
}

.graphChild-col-2{
  /* background-color: #f1f1f1; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 30%;
  margin: 5px;
  height: 230px;
  font-size: 0.75rem;
  flex-wrap: wrap;
}

.graphChild-col-3{
  /* background-color: #f1f1f1; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 30%;
   margin: 5px;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 230px;
  font-size: 0.75rem;
  flex-wrap: wrap;
}

.graphChild-col-4{
  /* background-color: #f1f1f1; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 30%;
   margin: 5px;
   height: 230px;
  text-align: center;
  line-height: 20px;
  font-size: 0.75rem;
display: flex;
flex-direction: column;
align-items: center;

}

.flex-GraphcontainerThree {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  /* background-color: DodgerBlue; */
}

.graphChild-colOne-1{
  /* background-color: #f1f1f1; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 20%;
  height: 250px;
   margin: 5px;
  text-align: center;

  font-size: 0.75rem;
  
}

.graphChild-colOne-2{
  /* background-color: #f1f1f1; */
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  width: 25%;
   margin: 5px;
  text-align: center;
  line-height: 75px;
  height: 200px;
  font-size: 0.75rem;
  flex-wrap: wrap;
}


.flex-top-firstConatiner{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dateManager-classInsights{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 15rem;
  margin: 1px;
  align-items: center;
  /* text-align: center;
  line-height: 40px;
  font-size: 0.80rem; */
  /* display:flex;
  flex-direction:column; */
   /* cursor: pointer; */
 
}

.matched-cando-classMatch{

  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 30rem;
 
  align-items: center;

  /* text-align: center;
  line-height: 40px;
  font-size: 0.80rem; */
  /* display:flex;
  flex-direction:column; */
   /* cursor: pointer; */
 
}

.dateManager-classInsights-one{
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 14rem;
 padding-left: 2.5rem;
 padding-right: 2rem;
 margin-top: 0.5rem;
  /* width: 15rem;
  margin: 3px; */
  /* text-align: center;
  line-height: 40px;
  font-size: 0.80rem; */
  /* display:flex;
  flex-direction:column; */
   /* cursor: pointer; */
 
}
.dateMatchedReq-class-one{
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 35rem;
  margin-top: 0.5rem;
  padding-left: 2.5rem;
  padding-right: 2rem;
 

  /* width: 15rem;
  margin: 3px; */
  /* text-align: center;
  line-height: 40px;
  font-size: 0.80rem; */
  /* display:flex;
  flex-direction:column; */
   /* cursor: pointer; */
 
}

.dateManagerReports-class-one{
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px; */
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  width: 33rem;


 

  /* width: 15rem;
  margin: 3px; */
  /* text-align: center;
  line-height: 40px;
  font-size: 0.80rem; */
  /* display:flex;
  flex-direction:column; */
   /* cursor: pointer; */
 
}



/* Manager View multi select field css */

.mySelect__value-container{
  height: 35px;
 }

 .css-wsp0cs-MultiValueGeneric:visited .mouse-over-popover{
  display: block;
 background-color: red;


 }

 .mouse-over-popover{
  display: none
 }

 /* a:hover {
  border-bottom:  3px solid #00f;
 
} */

/* .topnav a:active {
  border-bottom:  3px solid #00f;
 
} */

/* E360 CSS END */


