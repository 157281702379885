/* Ubuntu Font Family Bower-ready v0.1.13 */

/* BEGIN Ubuntu Light */
@font-face {
    font-family: 'Ubuntu';
    src: url('fonts/ubuntu-light-webfont.eot');
    src: url('fonts/ubuntu-light-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/ubuntu-light-webfont.woff2') format('woff2'),
    url('fonts/ubuntu-light-webfont.woff') format('woff'),
         url('fonts/ubuntu-light-webfont.ttf') format('truetype'),
         url('fonts/ubuntu-light-webfont.svg#ubuntulight') format('svg');
    font-weight: 300;
    font-style: normal;
}
/* END Ubuntu Light */

/* BEGIN Ubuntu Light Italic */
@font-face {
    font-family: 'Ubuntu';
    src: url('fonts/ubuntu-light-italic-webfont.eot');
    src: url('fonts/ubuntu-light-italic-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/ubuntu-light-italic-webfont.woff2') format('woff2'),
    url('fonts/ubuntu-light-italic-webfont.woff') format('woff'),
         url('fonts/ubuntu-light-italic-webfont.ttf') format('truetype'),
         url('fonts/ubuntu-light-italic-webfont.svg#ubuntulight_italic') format('svg');
    font-weight: 300;
    font-style: italic;
}
/* END Ubuntu Light Italic */

/* BEGIN Ubuntu Regular */
@font-face {
    font-family: 'Ubuntu';
    src: url('fonts/ubuntu-regular-webfont.eot');
    src: url('fonts/ubuntu-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/ubuntu-regular-webfont.woff2') format('woff2'),
         url('fonts/ubuntu-regular-webfont.woff') format('woff'),
         url('fonts/ubuntu-regular-webfont.ttf') format('truetype'),
         url('fonts/ubuntu-regular-webfont.svg#ubunturegular') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* END Ubuntu Regular */

/* BEGIN Ubuntu Regular Italic */
@font-face {
    font-family: 'Ubuntu';
    src: url('fonts/ubuntu-regular-italic-webfont.eot');
    src: url('fonts/ubuntu-regular-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/ubuntu-regular-italic-webfont.woff2') format('woff2'),
         url('fonts/ubuntu-regular-italic-webfont.woff') format('woff'),
         url('fonts/ubuntu-regular-italic-webfont.ttf') format('truetype'),
         url('fonts/ubuntu-regular-italic-webfont.svg#ubuntuitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}
/* END Ubuntu Regular Italic */

/* BEGIN Ubuntu Medium */
@font-face {
    font-family: 'Ubuntu';
    src: url('fonts/ubuntu-medium-webfont.eot');
    src: url('fonts/ubuntu-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/ubuntu-medium-webfont.woff2') format('woff2'),
         url('fonts/ubuntu-medium-webfont.woff') format('woff'),
         url('fonts/ubuntu-medium-webfont.ttf') format('truetype'),
         url('fonts/ubuntu-medium-webfont.svg#ubuntumedium') format('svg');
    font-weight: 500;
    font-style: normal;
}
/* END Ubuntu Medium */

/* BEGIN Ubuntu Medium Italic */
@font-face {
    font-family: 'Ubuntu';
    src: url('fonts/ubuntu-medium-italic-webfont.eot');
    src: url('fonts/ubuntu-medium-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/ubuntu-medium-italic-webfont.woff2') format('woff2'),
         url('fonts/ubuntu-medium-italic-webfont.woff') format('woff'),
         url('fonts/ubuntu-medium-italic-webfont.ttf') format('truetype'),
         url('fonts/ubuntu-medium-italic-webfont.svg#ubuntumedium_italic') format('svg');
    font-weight: 500;
    font-style: italic;
}
/* END Ubuntu Medium Italic */

/* BEGIN Ubuntu Bold */
@font-face {
    font-family: 'Ubuntu';
    src: url('fonts/ubuntu-bold-webfont.eot');
    src: url('fonts/ubuntu-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/ubuntu-bold-webfont.woff2') format('woff2'),
         url('fonts/ubuntu-bold-webfont.woff') format('woff'),
         url('fonts/ubuntu-bold-webfont.ttf') format('truetype'),
         url('fonts/ubuntu-bold-webfont.svg#ubuntubold') format('svg');
    font-weight: bold;
    font-style: normal;
}
/* END Ubuntu Bold */

/* BEGIN Ubuntu Bold Italic */
@font-face {
    font-family: 'Ubuntu';
    src: url('fonts/ubuntu-bold-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/ubuntu-bold-italic-webfont.woff2') format('woff2'),
         url('fonts/ubuntu-bold-italic-webfont.woff') format('woff'),
         url('fonts/ubuntu-bold-italic-webfont.ttf') format('truetype'),
         url('fonts/ubuntu-bold-italic-webfont.svg#ubuntubold_italic') format('svg');
    font-weight: bold;
    font-style: italic;
}
/* END Ubuntu Bold Italic */



/* BEGIN Ubuntu Condensed */
@font-face {
    font-family: 'Ubuntu Condensed';
    src: url('fonts/ubuntu-condensed-webfont.eot');
    src: url('fonts/ubuntu-condensed-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/ubuntu-condensed-webfont.woff2') format('woff2'),
         url('fonts/ubuntu-condensed-webfont.woff') format('woff'),
         url('fonts/ubuntu-condensed-webfont.ttf') format('truetype'),
         url('fonts/ubuntu-condensed-webfont.svg#ubuntu_condensedregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* END Ubuntu Condensed */



/* BEGIN Ubuntu Mono Regular */
@font-face {
    font-family: 'Ubuntu Monospace';
    src: url('fonts/ubuntumono-regular-webfont.eot');
    src: url('fonts/ubuntumono-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/ubuntumono-regular-webfont.woff2') format('woff2'),
         url('fonts/ubuntumono-regular-webfont.woff') format('woff'),
         url('fonts/ubuntumono-regular-webfont.ttf') format('truetype'),
         url('fonts/ubuntumono-regular-webfont.svg#ubuntu_monoregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
/* END Ubuntu Mono Regular */

/* BEGIN Ubuntu Mono Italic */
@font-face {
    font-family: 'Ubuntu Monospace';
    src: url('fonts/ubuntumono-regular-italic-webfont.eot');
    src: url('fonts/ubuntumono-regular-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/ubuntumono-regular-italic-webfont.woff2') format('woff2'),
         url('fonts/ubuntumono-regular-italic-webfont.woff') format('woff'),
         url('fonts/ubuntumono-regular-italic-webfont.ttf') format('truetype'),
         url('fonts/ubuntumono-regular-italic-webfont.svg#ubuntu_monoitalic') format('svg');
    font-weight: normal;
    font-style: italic;
}
/* END Ubuntu Mono Italic */

/* BEGIN Ubuntu Mono Bold */
@font-face {
    font-family: 'Ubuntu Monospace';
    src: url('fonts/ubuntumono-bold-webfont.eot');
    src: url('fonts/ubuntumono-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/ubuntumono-bold-webfont.woff2') format('woff2'),
         url('fonts/ubuntumono-bold-webfont.woff') format('woff'),
         url('fonts/ubuntumono-bold-webfont.ttf') format('truetype'),
         url('fonts/ubuntumono-bold-webfont.svg#ubuntu_monobold') format('svg');
    font-weight: bold;
    font-style: normal;
}
/* END Ubuntu Mono Bold */

/* BEGIN Ubuntu Mono Bold Italic */
@font-face {
    font-family: 'Ubuntu Monospace';
    src: url('fonts/ubuntumono-bold-italic-webfont.eot');
    src: url('fonts/ubuntumono-bold-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('fonts/ubuntumono-bold-italic-webfont.woff2') format('woff2'),
         url('fonts/ubuntumono-bold-italic-webfont.woff') format('woff'),
         url('fonts/ubuntumono-bold-italic-webfont.ttf') format('truetype'),
         url('fonts/ubuntumono-bold-italic-webfont.svg#ubuntu_monobold_italic') format('svg');
    font-weight: bold;
    font-style: italic;

}
/* END Ubuntu Mono Bold Italic */
